<template>
    <div class="common-title">
        <h3 class="title">{{ title }}</h3>
        <div class="common-content">
            <slot />
        </div>
    </div>
</template>

<script>
    export default {
        name: 'common-title',
        props: {
            title: {
                type: String,
                default: ''
            }
        }
    }
</script>

<style lang="less" scoped>
    .common-title {
        .title {
            margin-bottom: 30px;
            font-size: 20px;
            font-weight: bold;
        }
    }

    @media screen and (max-width: 1023px) {
        .common-title {
            .title {
                margin-bottom: 0.53333rem;
                font-size: 0.42666rem;
            }
        }
    }
</style>