<template>
    <div class="contrast-item">
        <header class="contrast-item-header">
            <h1>{{ data.title }}</h1>
        </header>
        <div class="contrast-item-content">
            <div class="left">
                <div v-for="(item, i) in data.left" :key="i">
                    <div class="num" v-if="item.type == 'num'">
                        <span>{{ item.desc.num }}</span>
                        <span>{{ item.desc.unit }}</span>
                    </div>
                    <template v-if="item.type == 'text'">
                        <p v-for="(desc, idx) in item.desc" :key="idx">{{ desc }}</p>
                    </template>
                    <ul v-if="item.type == 'list'">
                        <li v-for="(desc, idx) in item.desc" :key="idx">{{ `${idx + 1}. ${desc}` }}</li>
                    </ul>
                </div>
            </div>
            <div class="right">
                <div v-for="(item, i) in data.right" :key="i">
                    <div class="num" v-if="item.type == 'num'">
                        <span>{{ item.desc.num }}</span>
                        <span>{{ item.desc.unit }}</span>
                    </div>
                    <template v-if="item.type == 'text'">
                        <p v-for="(desc, idx) in item.desc" :key="idx">{{ desc }}</p>
                    </template>
                    <ul v-if="item.type == 'list'">
                        <li v-for="(desc, idx) in item.desc" :key="idx">{{ `${idx + 1}. ${desc}` }}</li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="bottom-info" v-if="data.bottom !== void 0 && data.bottom != ''">{{ data.bottom }}</div>
        <div class="bottom-circle" v-else></div>
        <!-- 二联数据 -->
        <template v-if="data.leftTwo != void 0">
            <div class="contrast-item-content">
                <div class="left">
                    <div v-for="(item, i) in data.leftTwo" :key="i">
                        <div class="num" v-if="item.type == 'num'">
                            <span>{{ item.desc.num }}</span>
                            <span>{{ item.desc.unit }}</span>
                        </div>
                        <template v-if="item.type == 'text'">
                            <p v-for="(desc, idx) in item.desc" :key="idx">{{ desc }}</p>
                        </template>
                        <ul v-if="item.type == 'list'">
                            <li v-for="(desc, idx) in item.desc" :key="idx">{{ `${idx + 1}. ${desc}` }}</li>
                        </ul>
                    </div>
                </div>
                <div class="right">
                    <div v-for="(item, i) in data.rightTwo" :key="i">
                        <div class="num" v-if="item.type == 'num'">
                            <span>{{ item.desc.num }}</span>
                            <span>{{ item.desc.unit }}</span>
                        </div>
                        <template v-if="item.type == 'text'">
                            <p v-for="(desc, idx) in item.desc" :key="idx">{{ desc }}</p>
                        </template>
                        <ul v-if="item.type == 'list'">
                            <li v-for="(desc, idx) in item.desc" :key="idx">{{ `${idx + 1}. ${desc}` }}</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="bottom-info" v-if="data.bottomTwo !== void 0 && data.bottomTwo != ''">{{ data.bottomTwo }}</div>
            <div class="bottom-circle" v-else></div>
        </template>
    </div>
</template>

<script>
    export default {
        name: 'contrast-item',
        props: {
            data: {
                type: Object,
                default () {
                    return {
                        title: '',
                        left: [],
                        right: [],
                        bottom: ''
                    }
                }
            }
        }
    }
</script>

<style lang="less" scoped>
    @import '~@/assets/less/common.less';

    .contrast-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 14px;
        .contrast-item-header {
            margin-bottom: 10px;
            font-size: 18px;
            h1 {
                font-weight: bold;
            }
        }
        .contrast-item-content {
            position: relative;
            display: flex;
            align-items: flex-start;
            .left, .right {
                flex: 1;
                padding: 20px;
                line-height: 1.5;
                .num {
                    span {
                        &:first-child {
                            font-size: 40px;
                            font-weight: bold;
                        }
                    }
                }
                &.left {
                    color: @theme-color;
                    .num {
                        text-align: right;
                    }
                }
                p, ul li {
                    margin-bottom: 6px;
                }
            }
            &:after {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
                display: block;
                width: 1px;
                background-color: #999;
                content: ''
            }
        }
        .bottom-circle {
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: #ccc;
        }
        .bottom-info {
            padding: 10px;
            width: 180px;
            text-align: center;
            color: #FFF;
            background-color: @theme-color;
            border-radius: 30px;
        }
    }
</style>