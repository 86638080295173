<template>
    <div class="evaluation">
        <header class="tags">
            <ul class="tag-list">
                <li
                    class="tag-item"
                    v-for="(tag, i) in tags"
                    :key="i"
                    :class="{'active': component == tag.english}"
                    @click="component = tag.english"
                >
                    {{ tag.title }}
                </li>
            </ul>
        </header>
        <div class="content">
            <consult :consult="data.consult" v-show="component == 'consult'" />
            <contrast :contrast="data.contrast" v-show="component == 'contrast'" />
        </div>
    </div>
</template>

<script>
    // 互联互通测评咨询
    // 组件
    import Consult from './Consult';
    import Contrast from './Contrast';

    // vuex
    import { mapMutations } from 'vuex';

    // api
    import { getEvaluation } from '@/api/request';

    export default {
        name: 'evaluation',
        created () {
            this.getEvaluationAjax();
        },
        data () {
            return {
                component: '',
                tags: [],
                data: {}
            }
        },
        methods: {
            ...mapMutations([
                'setBusinessBanners'
            ]),
            getEvaluationAjax () {
                getEvaluation().then(res => {
                    this.setBusinessBanners(res.banners);
                    this.tags = res.tags;
                    this.component = this.tags[0].english;
                    this.data = res;
                });
            }
        },
        components: {
            Consult,
            Contrast
        }
    }
</script>

<style lang="less" scoped>
    @import '~@/assets/less/common.less';

    .evaluation {
        .tags {
            border-bottom: 1px solid #ccc;
            .tag-list {
                display: flex;
                justify-content: space-between;
                .tag-item {
                    flex: 1;
                    position: relative;
                    padding: 20px 0;
                    text-align: center;
                    font-size: 16px;
                    cursor: pointer;
                    &:hover, &.active {
                        color: @theme-color;
                    }
                    &:after {
                        display: block;
                        position: absolute;
                        top: 50%;
                        right: -0.5px;
                        transform: translateY(-50%);
                        height: 10%;
                        width: 1px;
                        background-color: #777;
                        content: '';
                    }
                    &:last-child {
                        &:after {
                            display: none;
                        }
                    }
                }
            }
        }
        .content {
            padding: 20px 30px;
        }
    }

    @media screen and (max-width: 1023px) {
        .evaluation {
            .content {
                padding: 10px;
            }
        }
    }
</style>