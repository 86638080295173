<template>
    <div class="arraw">
        <div class="arraw-body" :class="{'position': !hasHead}"></div>
        <div class="arraw-head" v-if="hasHead"></div>
    </div>    
</template>

<script>
    export default {
        name: 'arraw',
        props: {
            hasHead: {
                type: Boolean,
                default: true
            }
        }
    }
</script>

<style lang="less" scoped>
    .arraw {
        .arraw-body {
            position: relative;
            top: 10px;
            left: 3px;
            height: 100vh;
            width: 2px;
            background-color: #ccc;
            &.position {
                left: 0;
            }
        }
        .arraw-head {
            position: relative;
            top: 10px;
            border-width: 10px 4px;
            border-color: #ccc transparent transparent transparent;
            border-style: solid;
        }
    }
</style>