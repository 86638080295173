<template>
    <div class="consult">
        <!-- 被测单位面临的问题 -->
        <common-title
            class="common"
            v-if="problem != ''"
            :title="problem.title"
        >
            <div class="problem-box">
                <img :src="problem.img" alt="">
                <span
                    v-for="(p, i) in problem.problem"
                    :key="i"
                >
                    {{ p }}
                </span>
            </div>
        </common-title>
        <!-- 解决方法 -->
        <common-title
            class="common"
            v-if="solve != ''"
            :title="solve.title"
        >
            <!-- 专业的服务 -->
            <div class="module-one">
                <module-title
                    :title="solve.modules.modulesOne.title"
                    :subTitle="solve.modules.modulesOne.subTitle">
                </module-title>
                <!-- 测试依据 -->
                <div class="test-basis">
                    <module-title
                        class="sub-module-title-native"
                        :title="solve.modules.modulesOne.basis.title"
                        :isModule="false" />
                    <div class="test-basis-content">
                        <ul class="test-basis-list">
                            <li v-for="(ba, i) in solve.modules.modulesOne.basis.base" :key="i">
                                {{ ba }}
                            </li>
                        </ul>
                        <img :src="solve.modules.modulesOne.basis.img" alt="" />
                    </div>
                </div>
                <!-- 测评内容 -->
                <div class="test-content">
                    <module-title
                        class="sub-module-title-native"
                        :title="solve.modules.modulesOne.content.title"
                        :isModule="false" />
                    <div class="imgs">
                        <img
                            v-for="(img, idx) in solve.modules.modulesOne.content.imgs"
                            :key="idx"
                            :src="img"
                            alt=""
                        />
                    </div>
                </div>
                <!-- 各等级最低要求分值情况 -->
                <div class="test-content">
                    <module-title
                        class="sub-module-title-native"
                        :title="solve.modules.modulesOne.grade.title"
                        :isModule="false" />
                    <img
                        :src="solve.modules.modulesOne.grade.img"
                        alt=""
                    />
                </div>
                <!-- 测评方法 -->
                <div class="test-method">
                    <module-title
                        class="sub-module-title-native"
                        :title="solve.modules.modulesOne.method.title"
                        :isModule="false" />
                    <div class="stages">
                        <div
                            class="stages-item"
                            v-for="(stage, idx) in solve.modules.modulesOne.method.stages"
                            :key="idx"
                        >
                            <div class="stage-left">
                                <div class="arrow-front-wrapper">
                                    <arraw
                                        class="arrow-front-box"
                                        v-if="idx != 0"
                                        :hasHead="true"
                                        direct="right"
                                    />
                                </div>
                                <h1>{{ stage.stage }}</h1>
                                <h2>{{ stage.stageName }}</h2>
                                <div class="arrow-back-wrapper">
                                    <arraw
                                        class="arrow-back-box"
                                        v-if="idx != solve.modules.modulesOne.method.stages.length - 1"
                                        :hasHead="false"
                                    />
                                </div>
                            </div>
                            <div class="stage-right">
                                <div class="stage-right-inner">
                                    <div
                                        class="stage-content"
                                        v-for="(ctn, index) in stage.stageContents"
                                        :key="index">
                                        <div class="stage-content-left">
                                            <h3>{{ ctn.left.title }}</h3>
                                            <p v-for="(p, iex) in ctn.left.desc" :key="iex">{{ p }}</p>
                                        </div>
                                        <div class="arraw-img">
                                            <img :src="`${imgBaseURL}img/evaluation/consult/arraw.png`" />
                                        </div> 
                                        <div class="stage-content-right">
                                            <h3>{{ ctn.right.title }}</h3>
                                            <p v-for="(p, iex) in ctn.right.desc" :key="iex">{{ p }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> 
                    </div> 
                </div>  
                <!-- 专家咨询 + 测试工具 -->
                <div class="professional">
                    <module-title
                        class="sub-module-title-native"
                        :subTitle="solve.modules.modulesOne.professional.subTitle"
                        :isModule="false" />
                    <ul class="module-list">
                        <li
                            class="module-item"
                            v-for="(info, i) in solve.modules.modulesOne.professional.descs"
                            :key="i">
                            <img :src="info.img" alt="" />
                            <p>{{ info.desc }}</p>
                        </li>
                    </ul>
                </div>
                <!-- 测评流程 -->
                <div class="flow">
                    <module-title
                        class="sub-module-title-native"
                        :subTitle="solve.modules.modulesOne.flow.subTitle"
                        :isModule="false" />
                    <img :src="solve.modules.modulesOne.flow.img" />
                </div>
            </div>
            <!-- 根据自测结果，定向针对具体问题，提出改进意见 -->
            <div class="module-two">
                <module-title
                    :title="solve.modules.modulesTwo.title"
                    :subTitle="solve.modules.modulesTwo.subTitle">
                </module-title>
                <!-- 对标准体系进行培训 -->
                <div class="stardard">
                    <module-title
                        class="sub-module-title-native"
                        :title="solve.modules.modulesTwo.standard.title"
                        :isModule="false" />
                    <ul class="module-list">
                        <li
                            class="stardard-item"
                            v-for="(stardard, i) in solve.modules.modulesTwo.standard.descs"
                            :key="i"
                        >
                            <img :src="stardard.img" alt="" />
                            <p>{{ stardard.desc }}</p>
                        </li>
                    </ul>
                </div>
                <!-- 对国家测评方案进行培训 -->
                <div class="country">
                    <module-title
                        class="sub-module-title-native"
                        :title="solve.modules.modulesTwo.country.title"
                        :isModule="false" />
                    <div class="country-content">
                        <ul class="country-list">
                            <li v-for="(item, i) in solve.modules.modulesTwo.country.desc" :key="i">{{ item }}</li>
                        </ul>
                        <div class="image">
                            <img :src="solve.modules.modulesTwo.country.img" alt="" />
                        </div>
                    </div>
                </div>
                <!-- 提出改进意见，供院方自助选择，协助其进行改进 -->
                <div class="adjust">
                    <module-title
                        class="sub-module-title-native"
                        :title="solve.modules.modulesTwo.adjust.title"
                        :isModule="false" />
                    <ul class="adjust-list">
                        <li v-for="(adj, i) in solve.modules.modulesTwo.adjust.desc" :key="i">
                            <span class="mark">{{ adj.mark }}</span>
                            <p>{{ adj.desc }}</p>
                        </li>
                    </ul>
                </div>
            </div>
            <!-- 提供评审资料整理服务 -->
            <div class="module-three">
                <module-title
                    :title="solve.modules.modulesThree.title">
                </module-title>
                <div class="three-info">
                    <p>{{ solve.modules.modulesThree.desc }}</p>
                    <img :src="solve.modules.modulesThree.img" alt="" />
                </div>
            </div>
        </common-title>
        <!-- 咨询测评案列 -->
        <common-title
            class="common"
            v-if="caseInfo != ''"
            :title="caseInfo.title"
        >
            <!-- 如何准备申请材料 -->
            <div class="apply">
                <module-title
                    class="sub-module-title-native"
                    :title="caseInfo.apply.title" />
                <div class="apply-content">
                    <tree class="tree" :data="caseInfo.apply.tree" />
                    <img class="img" :src="caseInfo.apply.img" alt="" />
                </div>
            </div>
            <!-- 常见问题解答 -->
            <div class="qs">
                <module-title
                    class="sub-module-title-native"
                    :title="caseInfo.common.title" />
                <ul class="qs-content">
                    <li v-for="(qs, i) in caseInfo.common.questions" :key="i">
                        <div class="question">{{ qs.question }}</div>
                        <div class="answer">{{ qs.answer }}</div>
                    </li>
                </ul>
            </div>
        </common-title>
    </div>
</template>

<script>
    // 咨询
    // 组件
    import ModuleTitle from '@/components/FhiryModuleTitle';
    import CommonTitle from './CommonTitle';
    import Arraw from './Arraw';
    import Tree from './Tree';

    // vuex
    import { mapState } from 'vuex';

    export default {
        name: 'consult',
        props: {
            consult: {
                type: Object,
                default () {
                    return {};
                }
            }
        },
        computed: {
            problem () {
                if (this.consult.problem != void 0) {
                    return this.consult.problem;
                }
                return '';
            },
            solve () {
                if (this.consult.solve != void 0) {
                    return this.consult.solve;
                }
                return '';
            },
            caseInfo () {
                if (this.consult.case != void 0) {
                    return this.consult.case;
                }
                return '';
            },
            ...mapState([
                'imgBaseURL'
            ])
        },
        components: {
            CommonTitle,
            ModuleTitle,
            Arraw,
            Tree
        }
    }
</script>

<style lang="less" scoped>
    @import '~@/assets/less/common.less';

    .consult {
        .common {
            margin-bottom: 50px;
            &:last-child {
                margin-bottom: 0;
            }
            .problem-box {
                position: relative;
                text-align: center;
                span {
                    position: absolute;
                    color: #777;
                    &:nth-child(2) {
                        top: 5px;
                        left: 64px;
                    }
                    &:nth-child(3) {
                        top: 109px;
                        left: 67px;
                    }
                    &:nth-child(4) {
                        top: 214px;
                        left: 34px;
                    }
                    &:nth-child(5) {
                        top: 216px;
                        right: 40px;
                    }
                    &:nth-child(6) {
                        top: 109px;
                        right: 22px;
                    }
                    &:nth-child(7) {
                        top: 5px;
                        right: -10px;
                    }
                }
            }
            .module-one, .module-two, .apply, .qs {
                margin-bottom: 50px;
                .test-content, .test-basis, .test-method,
                .professional, .flow, .stardard, .country, .adjust,
                .apply-content, .qs-content {
                    margin-top: 50px;
                    .sub-module-title-native {
                        margin-bottom: 50px;
                    }
                }
                // 专家咨询 + 测试工具
                // 对标准体系进行培训
                .professional, .stardard {
                    .module-list {
                        display: flex;
                        justify-content: space-between;
                        li {
                            flex: 1;
                            margin-right: 20px;
                            text-align: center;
                            &:last-child {
                                margin-right: 0;
                            }
                            p {
                                color: #777;
                                text-align: center;
                                font-size: 14px;
                            }
                        }
                    }
                }
            }
            // 专业的服务
            .module-one {    
                // 测试依据
                .test-basis {
                    .test-basis-content {
                        position: relative;
                        margin-top: 10px;
                        .test-basis-list {
                            position: relative;
                            left: 16px;
                            list-style: disc;
                            color: #777;
                            font-size: 14px;
                            line-height: 2;
                        }
                        img {
                            position: absolute;
                            right: 0;
                            top: 50%;
                            transform: translateY(-50%);
                        }
                    }
                }
                // 测试内容
                .test-content {
                    .imgs {
                        display: flex;
                        img {
                            width: calc(~'50% - 10px');
                            &:first-child {
                                margin-right: 20px;
                                height: 255px;
                            }
                        }
                    }
                }
                // 测评方法
                .test-method {
                    .stages {
                        .stages-item {
                            position: relative;
                            display: flex;
                            align-items: center;
                            overflow: hidden;
                            .stage-left {
                                position: relative;
                                top: -10px;
                                text-align: center;
                                width: 70px;
                                color: #999;
                                .arrow-front-wrapper, .arrow-back-wrapper {
                                    position: relative;
                                    .arrow-back-box, .arrow-front-box {
                                        position: absolute;
                                        left: 50%;
                                        transform: translateX(-50%);
                                    }
                                    .arrow-back-box {
                                        top: 0;
                                    }
                                    .arrow-front-box {
                                        bottom: 0;
                                    }
                                }
                                h1 {
                                    font-size: 50px;
                                    font-weight: bold;
                                }
                                h2 {
                                    font-size: 16px;
                                }
                            }
                            .stage-right {
                                margin-left: 20px;
                                padding-bottom: 20px;
                                width: calc(~'100% - 90px');
                                .stage-right-inner {
                                    padding: 15px;
                                    color: #777;
                                    font-size: 12px;
                                    background-color: #f2f2f2;
                                    border-radius: 10px;
                                    .stage-content {
                                        display: flex;
                                        align-items: center;
                                        margin-bottom: 20px;
                                        &:last-child {
                                            margin-bottom: 0;
                                        }
                                        .stage-content-right, .stage-content-left {
                                            padding: 10px 16px;
                                            border-radius: 5px;
                                            h3 {
                                                margin-bottom: 6px;
                                                font-size: 16px;
                                                font-weight: bold;
                                            }
                                            p {
                                                line-height: 1.5;
                                            }
                                        }
                                        .stage-content-left {
                                            width: 30%;
                                            background-color: #FFF;
                                            border: 1px solid #e8e8e8;
                                            h3 {
                                                color: #000;
                                            }
                                        }
                                        .arraw-img {
                                            width: 5%;
                                        }
                                        .stage-content-right {
                                            width: 65%;
                                            background-color: #fcf5eb;
                                            border: 1px solid #ddc8a7;
                                            h3 {
                                                color: #d0a051;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            // 根据自测结果，定向针对具体问题，提出改进意见
            .module-two {
                // 对国家测评方案进行培训
                .country {
                    .country-content {
                        display: flex;
                        justify-content: space-between;
                        font-size: 14px;
                        .country-list {
                            position: relative;
                            left: 14px;
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;
                            width: calc(~'50%');
                            color: #999;
                            line-height: 1.5;
                            list-style: decimal;
                        }
                        .image {
                            width: calc(~'50% - 50px');
                        }
                    }
                }
                // 提出改进意见，供院方自助选择，协助其进行改进
                .adjust {
                    .adjust-list {
                        display: flex;
                        flex-wrap: wrap;
                        li {
                            position: relative;
                            padding: 60px 20px 20px;
                            margin: 0 20px 20px 0;
                            width: calc(~'50% - 10px');
                            color: #cd9945;
                            font-size: 12px;
                            line-height: 2;
                            border: 2px solid #d2a459;
                            &:nth-child(2n) {
                                margin-right: 0;
                            }
                            &:nth-last-child(-n + 2) {
                                margin-bottom: 0;
                            }
                            .mark {
                                position: absolute;
                                top: 0;
                                left: 0;
                                width: 45px;
                                height: 45px;
                                line-height: 45px;
                                color: #FFF;
                                text-align: center;
                                font-size: 24px;
                                background-color: #cd9945;
                            }
                            p {
                                font-size: 14px;
                            }
                        }
                    }
                }
            }
            // 提供评审资料整理服务
            .module-three {
                position: relative;
                .three-info {
                    position: relative;
                    top: -29px;
                    display: flex;
                    align-items: center;
                    font-size: 14px;
                    p {
                        margin-right: 50px;
                        line-height: 1.5;
                    }
                }
            }
            // 如何准备申请材料
            .apply {
                .apply-content {
                    position: relative;
                    display: flex;
                    align-items: center;
                    .tree {
                        position: relative;
                        z-index: 999;
                        width: 65%;
                    }
                    .img {
                        position: absolute;
                        right: 0;
                        height: 240px;
                        z-index: 0;
                    }
                }
            }
            // 常见问题解答
            .qs {
                .qs-content {
                    display: flex;
                    justify-content: space-between;
                    li {
                        margin-right: 20px;
                        font-size: 14px;
                        .question {
                            margin-bottom: 10px;
                            color: @theme-color;
                        }
                        .answer {
                            line-height: 1.5;
                        }
                        &:last-child {
                            margin-right: 0;
                        }
                    }
                }
            }
        }
    }

    @media screen and (max-width: 1023px) {
        .consult {
            .common {
                .module-one, .module-two {
                    .professional, .stardard {
                        .module-list {
                            li {
                                margin-right: 0.26666rem;
                                p {
                                    font-size: 0.26666rem;
                                }
                            }
                        }
                    }
                }   
                .module-one {
                    .test-content {
                        .imgs {
                            flex-direction: column;
                            img {
                                width: 100%;
                                &:first-child {
                                    margin-right: 0;
                                    margin-bottom: 20px;
                                    height: auto;
                                }
                            }
                        }
                    }
                }
                .module-two {
                    .country {
                        .country-content {
                            flex-direction: column;
                            font-size: 12px;
                            .image, .country-list {
                                width: 100%;
                            }
                            .country-list {
                                padding-right: 20px;
                                line-height: 2;
                            }
                        }
                    }
                }       
                .qs {
                    .qs-content {
                        display: flex;
                        justify-content: space-between;
                        li {
                            font-size: 0.32rem;
                            .question {
                                height: 36px;
                            }
                        }
                    }
                }
            }
        }
    }

    @media screen and (max-width: 767px) {
        .consult {
            .common {
                .problem-box {
                    margin-top: 40px;
                    img {
                        width: 120px;
                    }
                    span {
                        display: block;
                        width: 82px;
                        position: absolute;
                        color: #777;
                        font-size: 10px;
                        &:nth-child(2) {
                            top: -12px;
                            left: 12px;
                        }
                        &:nth-child(3) {
                            top: 24px;
                            left: 10px;
                        }
                        &:nth-child(4) {
                            top: 59px;
                            left: 6px;
                        }
                        &:nth-child(5) {
                            top: 23px;
                            right: 5px;
                        }
                        &:nth-child(6) {
                            top: -12px;
                            right: 7px;
                        }
                        &:nth-child(7) {
                            top: 59px;
                            right: 7px;
                        }
                    }
                }
                .module-one {
                    .test-basis {
                        .test-basis-content {
                            .test-basis-list {
                                font-size: 10px;
                                li {
                                    padding-right: 20px;
                                    width: 100%;
                                }
                            }
                            img {
                                display: block;
                                position: static;
                                margin: 10px auto 0;
                                transform: translateX(0px);
                            }
                        }
                    }
                }
                .module-three {
                    .three-info {
                        position: static;
                        flex-direction: column;
                        font-size: 12px;
                        p {
                            margin: 20px 0 16px 0;
                        }
                        img {
                            width: 100%;
                        }
                    }
                } 
                .apply {
                    .apply-content {
                        flex-direction: column;
                        .tree, .img {
                            width: 100%;
                        }
                        .img {
                            position: static;
                        }
                    }
                }
            }
        }
    }
</style>