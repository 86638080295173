<template>
    <div class="contrast">
        <header class="header">
            <img :src="contrast.img" alt="">
        </header>
        <div class="one">
            <contrast-item
                class="item"
                v-for="(item, i) in one"
                :key="i"
                :data="item"
            />
        </div>
        <div class="two">
            <header class="my-header">
                <h2>{{ two.title }}</h2>
            </header>
            <div class="box">
                <contrast-item
                    class="item"
                    v-for="(item, i) in two.data"
                    :key="i"
                    :data="item"
                />
            </div>
        </div>
        <div class="two">
            <header class="my-header">
                <h2>{{ three.title }}</h2>
            </header>
            <div class="box">
                <contrast-item
                    class="item"
                    v-for="(item, i) in three.data"
                    :key="i"
                    :data="item"
                />
            </div>
        </div>
        <div class="two">
            <header class="my-header">
                <h2>{{ four.title }}</h2>
            </header>
            <div class="box">
                <contrast-item
                    class="item"
                    v-for="(item, i) in four.data"
                    :key="i"
                    :data="item"
                />
            </div>
        </div>
        <div class="vs-box">
            <header class="stage-request">
                <span class="stage-header-item new">
                    <span class="year">2020</span>
                    <span class="unit">年新版</span>
                </span>
                <span class="stage-header-item mid">分级要求</span>
                <span class="stage-header-item">
                    <span class="year">2017</span>
                    <span class="unit">年旧版</span>
                </span>
            </header>
            <div class="vs-container" v-if="contrast.vs != void 0">
                <ul class="vs-list">
                    <li class="vs-item" v-for="(item, idx) in contrast.vs.datas" :key="idx">
                        <div class="vs-left">
                            <ul class="vs-item-list" v-for="(it, index) in item.v2020List" :key="index">
                                <li>{{ it }}</li>
                            </ul>
                        </div>
                        <div class="vs-title">
                            <div class="vs-warpper">
                                <div class="vs-inner-wrapper">
                                    <h2>{{ item.rank }}</h2>
                                </div>
                            </div>
                        </div>
                        <div class="vs-right">
                            <ul class="vs-item-list" v-for="(it, index) in item.v2017List" :key="index">
                                <li>{{ it }}</li>
                            </ul>
                        </div>
                    </li>  
                </ul>
                <div class="summarize">{{ contrast.vs.summarize }}</div>
            </div>
        </div>
        <div class="change-direct">
            <h1>修订方向</h1>
            <div class="direct-box" v-if="contrast.directs != void 0">
                <!-- <div class="bg-img" style="width: 100%;">
                    <img class="bg" :src="`${imgBaseURL}/img/evaluation/contrast/bg.png`" alt="" style="width: 100%;" />
                    <div class="grid-box">
                        <img :src="`${imgBaseURL}/img/evaluation/contrast/11.png`" alt="" />
                        <img :src="`${imgBaseURL}/img/evaluation/contrast/44.png`" alt="" />
                        <img :src="`${imgBaseURL}/img/evaluation/contrast/11.png`" alt="" />
                        <img :src="`${imgBaseURL}/img/evaluation/contrast/44.png`" alt="" />
                    </div>
                </div> -->
                <div class="direct-list">
                    <div class="direct-item" v-for="(item, idx) in contrast.directs" :key="idx">
                        <template v-if="idx == 0">
                            <img :src="`${imgBaseURL}/img/evaluation/contrast/11.png`"
                                 alt=""
                                 class="bg-img"
                            />
                        </template>
                        <template v-else-if="idx == contrast.directs.length - 1">
                            <img :src="`${imgBaseURL}/img/evaluation/contrast/44.png`"
                                 alt=""
                                 class="bg-img"
                            />
                        </template>
                        <template v-else-if="(idx + 1) % 2 == 0">
                            <img :src="`${imgBaseURL}/img/evaluation/contrast/23.png`"
                                 alt=""
                                 class="bg-img bg-img-max"
                            />
                            <img :src="`${imgBaseURL}/img/evaluation/contrast/44.png`"
                                 alt=""
                                 class="bg-img bg-img-small"
                            />
                        </template>
                        <template v-else>
                            <img :src="`${imgBaseURL}/img/evaluation/contrast/23.png`"
                                 alt=""
                                 class="bg-img"
                            />
                        </template>
                        <div class="direct-content">
                            <header class="direct-item-header">
                                <img :src="`${imgBaseURL}/img/evaluation/contrast/0${idx + 1}.png`" />
                                <h2>{{ item.title }}</h2>
                                <h3>{{ item.subTitle }}</h3>
                            </header>
                            <ul class="direct-expands">
                                <li v-for="(it, index) in item.list" :key="index">
                                    <div>{{ it.title }}</div>
                                    <div v-if="it.subTitle != void 0">{{ it.subTitle }}</div>
                                </li>  
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import ContrastItem from './ContrastItem';

    // vuex
    import { mapState } from 'vuex';

    // 对比
    export default {
        name: 'contrast',
        props: {
            contrast: {
                type: Object,
                default () {
                    return {
                        img: '',
                        modules: {}
                    }
                }
            }
        },
        computed: {
            one () {
                if (this.contrast.modules.one == void 0) {
                    return [];
                }
                return this.contrast.modules.one;
            },
            two () {
                if (this.contrast.modules.two == void 0) {
                    return [];
                }
                return this.contrast.modules.two;
            },
            three () {
                if (this.contrast.modules.three == void 0) {
                    return [];
                }
                return this.contrast.modules.three;
            },
            four () {
                if (this.contrast.modules.four == void 0) {
                    return [];
                }
                return this.contrast.modules.four;
            },
            ...mapState([
                'imgBaseURL'
            ])
        },
        components: {
            ContrastItem
        }
    }
</script>

<style lang="less" scoped>
    .contrast {
        .header {
            margin: 50px 0;
            text-align: center;
        }
        .one {
            column-count: 2;
            column-gap: 10px;
            .item {
                break-inside: avoid;
                margin-bottom: 50px;
            }
        }
        .two {
            margin-bottom: 50px;
            .my-header {
                margin-bottom: 50px;
                text-align: center;
                h2 {
                    display: inline;
                    padding: 6px 20px;
                    color: #FFF;
                    font-size: 20px;
                    font-weight: bold;
                    background-color: #cccccc;
                    border-radius: 3px;
                }
            }
            .box {
                column-count: 2;
                column-gap: 10px;
                .item {
                    break-inside: avoid;
                    margin-bottom: 50px;
                }
            }
        }
        .vs-box {
            .stage-request {
                display: flex;
                align-items: baseline;
                justify-content: space-around;
                .stage-header-item {
                    color: #808080;
                    font-size: 18px;
                    .year {
                        font-size: 26px;
                        font-weight: bold;
                    }
                    &.new {
                        color: #ea243b;
                    }
                    &.mid {
                        padding: 4px 25px;
                        color: #FFF;
                        letter-spacing: 1px;
                        border-radius: 3px;
                        background-color: #bbb;
                    }
                }
            }
            .vs-container {
                position: relative;
                margin-top: 30px;
                .vs-list {
                    .vs-item {
                        position: relative;
                        display: flex;
                        justify-content: center;
                        margin-bottom: 10px;
                        .vs-title {
                            position: absolute;
                            align-self: center;
                            .vs-warpper {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                width: 80px;
                                height: 80px;
                                background-color: #FFF;
                                border-radius: 50%;
                                .vs-inner-wrapper {
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    width: 74px;
                                    height: 74px;
                                    background-color: #d3ba91;
                                    border-radius: inherit;
                                    h2 {
                                        width: 36px;
                                        color: #FFF;
                                        letter-spacing: 2px;
                                        font-size: 16px;
                                    }
                                }
                            }
                        }
                        .vs-left, .vs-right {
                            flex: 1;
                            padding: 24px 24px;
                            color: #FFF;
                            font-size: 14px;
                            line-height: 2;
                            &.vs-left {
                                margin-right: 10px;
                                padding-right: 50px;
                                background-color: #ea243b;
                            }
                            &.vs-right {
                                padding-left: 50px;
                                background-color: #808080;
                            }
                        }
                    }
                }
                .summarize {
                    position: absolute;
                    left: 50%;
                    bottom: -14px;
                    transform: translateX(-50%);
                    padding: 6px 30px;
                    width: 60%;
                    color: #FFF;
                    background-color: #d3ba91;
                    border-radius: 13px;
                    letter-spacing: 1px;
                    white-space: nowrap;
                }
            }
        }
        .change-direct {
            margin-top: 60px;
            margin-bottom: 100px;
            h1 {
                margin-bottom: 20px;
                font-size: 18px;
                font-weight: bold;
            }
            .direct-box {
                .direct-list {
                    display: flex;
                    justify-content: space-between;
                    flex-wrap: wrap;
                    .direct-item {
                        position: relative;
                        flex: 1;
                        .bg-img {
                            position: absolute;
                            height: 100%;
                            width: 100%;
                            &.bg-img-max {
                                display: block;
                            }
                            &.bg-img-small {
                                display: none;
                            }
                        }
                        .direct-content {
                            padding: 10px 17% 10px 10%;
                            .direct-item-header {
                                height: 110px;
                                h2 {
                                    margin-bottom: 4px;
                                    font-size: 14px;
                                    font-weight: bold;
                                }
                                h3 {
                                    color: #999;
                                }
                            }
                            .direct-expands {
                                li {
                                    margin-bottom: 4px;
                                    padding: 8px 14px;
                                    color: #FFF;
                                    text-align: center;
                                    border-radius: 20px;
                                    background-color: #b2b1af;
                                    div {
                                        font-size: 12px;
                                    }
                                    &:last-child {
                                        margin: 0;
                                    }
                                }
                            }
                        }
                        &:last-child {
                            flex: 0.85;
                        }
                    }
                }
            }
        }
    }

    @media screen and (max-width: 1023px) {
        .contrast {
            .header {
                margin: 30px 0;
            }
            .one {
                column-count: 1;
            }
            .two {
                .box {
                    column-count: 1;
                }
            }
            .vs-box {
                .vs-container {
                    margin-bottom: 30px;
                    .summarize {
                        padding: 6px 15px;
                        bottom: -22px;
                        white-space: normal;
                    }
                }
            }
            .change-direct {
                .direct-box {
                    .direct-list {
                        .direct-item {
                            flex: none;
                            margin-bottom: 10px;
                            width: 50%;
                            .bg-img {
                                &.bg-img-max {
                                    display: none !important;
                                }
                                &.bg-img-small {
                                    display: block !important;
                                }
                            }
                            &:nth-child(2n + 1) {
                                width: 55%;
                            }
                            &:nth-child(2n) {
                                flex: none;
                                width: 45%;
                            }
                        }
                    }
                }
            }
        }
    }

    @media screen and (max-width: 400px) {
        .contrast {
            .vs-box {
                .stage-request {
                    .stage-header-item {
                        color: #808080;
                        font-size: 14px;
                        .year {
                            font-size: 18px;
                            font-weight: bold;
                        }
                        &.new {
                            color: #ea243b;
                        }
                        &.mid {
                            padding: 4px 25px;
                            color: #FFF;
                            letter-spacing: 1px;
                            border-radius: 3px;
                            background-color: #bbb;
                        }
                    }
                }
            }
        }
    }
</style>