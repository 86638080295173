<template>
    <ul class="tree-list">
        <li class="tree-item" v-for="(tree, i) in data" :key="i">
            <span>{{ tree.title }}</span>
            <tree
                class="tree-child"
                v-if="tree.children != void 0 && tree.children.length > 0"
                :data="tree.children"
                :style="{'paddingLeft': `${14 * tree.indent}px`, 'color': '#777'}"
            />
        </li>
    </ul>
</template>

<script>
    // 如何准备申请材料

    export default {
        name: 'tree',
        props: {
            data: {
                type: Array,
                default () {
                    return [];
                }
            }
        }
    }
</script>

<style lang="less" scoped>
    .tree-list {
        font-size: 14px;
        span {
            line-height: 2;
        }
    }
</style>